const size = {
  mobileL: '640px',
  tablet: '800px',
  laptopS: '1100px',
  laptopL: '1440px',
  midDevice: `641px`,
  midDevice2: `801px`
};

export const device = {
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptopS: `(max-width: ${size.laptopS})`,
  laptopL: `(max-width: ${size.laptopL})`,
  midDevice: `(min-width: ${size.midDevice})`,
  midDevice2: `(min-width: ${size.midDevice2})`
};
