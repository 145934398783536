import React, { Component } from 'react';
import iffun from '@bit/rivigo.ui.utils.iffun';
import Spinner from '@bit/rivigo.ui.spinner';
import styled from 'styled-components';
import Header from '../components/header';
// import LiveRateTicker from '../components/LiveRateTicker';
import RateTicker from '../components/RateTicker';
// import AppDownload from '../components/AppDownload';
import Footer from '../components/footer';
// import FiltersLeftPane from '../components/FiltersLeftPane';
import HeroImage from '../images/banner-new.jpg';
// import {
//   transformRateData,
//   transformTopLanesData,
//   trasnformWeightData,
//   transformFuelData
// } from '../utils/csvDataManipulation';
import ErrorBoundary from '../components/ErrorBoundary';
import sendErrorLogToSentry from '../utils/sendSentryLog';
import SEO from '../components/seo';
import { device } from '../constants/device';
import userServices from '../services/userServices';

import MainPage from '../components-new/MainPage';

const keywords = [
  'National Freight Index',
  'road',
  'Trucking',
  'Prices Rates',
  'India',
  'Spot Rate',
  'Container',
  'Open',
  'Trailer',
  'rupees per ton km',
  'road trends'
];

const MainHeading = styled.div`
  position: relative;
  background: url(${HeroImage}) no-repeat scroll center;
  background-size: cover;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h1 {
    font-size: 46px;
    color: #ffffff;
    padding: 50px 0;
    text-align: right;
    small {
      font-size: 16px;
      display: block;
      margin-bottom: 5px;
    }
  }
  @media ${device.mobileL} {
    h1 {
      padding: 20px 10px;
      font-size: 24px;
      small {
        text-align: right;
        font-size: 12px;
      }
    }
  }
`;

class IndexPage extends Component {
  state = {
    isUserLoggedIn: false,
    userMobile: null,
    isXhrInProgress: true,
    truckMappings: null,
    nfiHubPriceList: [],
    nfiHubs: {}
    // processedNfiData: {}
  };

  processedPriceData = (data, cities) => {
    let priceData = [];
    data.forEach((item) => {
      if(item.currentPrice && item.currentPrice !== 0) {
      let origin = cities.find((city) => city.id === item.originHubId);
      let destination = cities.find((city) => city.id === item.destinationHubId);
      let originCity = origin ? origin.city : '';
      let destinationCity = destination ? destination.city : '';
      if (origin !== '' && destination !== '' && origin.id === destination.id) {
        return;
      }
      priceData.push({
        originCity,
        destinationCity,
        originHubId: item.originHubId,
        destinationHubId: item.destinationHubId,
        currentPrice: item.currentPrice,
        lastWeekPrice: item.lastWeekPrice,
        percentageChange: item.percentageChange
      });
      }
    });
    return priceData;
  };
  async componentDidMount() {
    // transformRateData();
    // trasnformWeightData();
    // transformFuelData();
    // transformTopLanesData();
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('sessionToken')) {
        this.setState({ isXhrInProgress: true });
        let checkUser = await userServices.authenticateUser();
        // this.setState({ isXhrInProgress: false });
        if (checkUser && checkUser.mobile && checkUser.mobile !== '') {
          window.localStorage.setItem('userMobile', checkUser.mobile);
          this.setState({ userMobile: checkUser.mobile, isUserLoggedIn: true });
        } else {
          window.localStorage.removeItem('sessionToken');
          window.localStorage.removeItem('userMobile');
        }
      }
    }
    // let getMappings = await userServices.getMappings();
    // this.setState({ truckMappings: getMappings });
    let getNfiHubPriceList = await userServices.getAllNFIHubPrices();
    let getNfiHubs = await userServices.getAllNFIHubs();
    let processedNfiData = this.processedPriceData(getNfiHubPriceList.priceNfiDtos, getNfiHubs.hubDtos);
    this.setState({
      nfiHubPriceList: processedNfiData,
      nfiHubs: getNfiHubs.hubDtos
    });

    this.setState({ isXhrInProgress: false });
  }

  render() {
    const { isUserLoggedIn, userMobile, isXhrInProgress, nfiHubPriceList, nfiHubs } = this.state;
    return (
      <ErrorBoundary handleError={(err) => sendErrorLogToSentry(err)} showOnError={<h2>Something went wrong</h2>}>
        <SEO keywords={keywords} title='National Freight Index powered by Rivigo' />
        {iffun(
          isXhrInProgress === true,
          <div style={{ width: '100%', minHeight: '100%', position: 'relative' }}>
            <Spinner />
          </div>,
          <div>
            <Header isUserLoggedIn={isUserLoggedIn} userMobile={userMobile} />
            <MainHeading>
              <div>
                <h1>
                  NATIONAL FREIGHT INDEX
                  <small>by RIVIGO</small>
                </h1>
              </div>
            </MainHeading>
            <RateTicker nfiHubPriceList={nfiHubPriceList} />
            <MainPage isUserLoggedIn={isUserLoggedIn} nfiHubPriceList={nfiHubPriceList} nfiHubs={nfiHubs} />
            {/* <LiveRateTicker isUserLoggedIn={isUserLoggedIn} truckMappings={truckMappings} /> */}
            {/* <FiltersLeftPane truckMappings={truckMappings} isUserLoggedIn={isUserLoggedIn} />  */}
            {/* <AppDownload userMobile={userMobile} /> */}
            <Footer />
          </div>
        )}
      </ErrorBoundary>
    );
  }
}

export default IndexPage;
