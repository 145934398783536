import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Chart } from 'react-google-charts';
import { userServices } from '../services';
import Spinner from '@bit/rivigo.ui.spinner';
import customToast from '../utils/customToast';
import { device } from '../constants/device';
import constants from '../constants/jsonDataConstants';

const Container = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  position:'relative'
`;
const GraphWrapper = styled.div`
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
`;
const GraphTitle = styled.h2`
  padding: 10px 0;
  background: #e6e6e6;
  color: #858383;
  font-size: 14px;
`;

const DownloadGraphWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  button {
    max-width: 45%;
    cursor: pointer;
    color: #e33232;
    background-color: #fff;
    border: 1px solid #e33232;
    border-radius: 36px;
    padding: 8px 10px;
    line-height: 1;
    text-align: center;
    &:hover {
      background: #e33232;
      color: #fff;
    }
  }
`;


function createCustomTooltip(type, date, value) {
  return `<div style="padding: 10px 15px;">
  <div style="font-size: 12px; font-weight: bold;">${date}</div>
  <div style="font-size: 12px; font-weight: light;">${type} ${value}</div>
  </div>`;
}


const FreightIndexGraph = () => {
  const [freightIndex, setFreightIndex] = useState([]);
  const [avgFuelPrice, setAvgFuelPrice] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([['x', 'Freight index', 'Avg. fuel rate (Top 4 Metro cities)']]);
  let options = {
    hAxis: {
      format: 'MMM',
      viewWindow: {
        min: new Date(2022, 0, -20),
        max: new Date(2022, 11, 1) // to be removed after 2022 is over
      }
    },
    chartArea: {
      width: '90%',
    },
    legend: {
      position: 'top', alignment: 'end'
    },
    annotations: {
      boxStyle: {
        stroke: '#c0c0c0',
        strokeWidth: 1,
        rx: 2,
        gradient: {
          color1: '#ffffff',
          color2: '#ffffff',
          x1: '0%',
          y1: '0%',
          x2: '100%',
          y2: '100%'
        }
      },
      textStyle: {
        color: '#2a2a2a',
        fontSize: 12
      },
      stem: {
        color: 'transparent'
      },
      alwaysOutside: true
    },
    tooltip: {
      isHtml: true
    },
    seriesType: 'bars',
    series: {
      1: {
        type: 'line',
        color: '#F5A623',
        lineWidth: 4,
        pointSize: 12
      }
    },
    backgroundColor: 'none',
    colors: ['#969696'],
    vAxis:{
      viewWindow: {
       min: 0
      }
    }

  };

  const handleDownloadChart = async () => {
    const { default: html2canvas } = await import('html2canvas');
    let node = document.getElementById('chart');
    await html2canvas(node, {
      useCORS: true,
      scale: 2
    }).then((canvas) => {
      let imgData = canvas.toDataURL('image/png');
      let currDate = new Date();
      let dd = currDate.getDate();
      let mm = currDate.getMonth() + 1;
      const yyyy = currDate.getFullYear();
      if (dd < 10) {
        dd = `0${dd}`;
      }
      if (mm < 10) {
        mm = `0${mm}`;
      }
      let time = `${currDate.getHours()}${currDate.getMinutes()}`;
      currDate = `${yyyy}-${mm}-${dd}`;
      let a = document.createElement('a');
      a.href = imgData;
      a.download = `NFI_Rivigo_${currDate}-${time}.png`;
      a.click();
    });
  };

  useEffect(() => {
    userServices
      .getFreightIndex()
      .then((res) => {
        setFreightIndex(res.freightIndexDataDtos);
      })
      .catch((err) => {
        customToast('error', err.response.message || 'Something went wrong');
      });
    userServices
      .getAvgFuelRate()
      .then((res) => {
        setAvgFuelPrice(res.freightIndexDataDtos);
      })
      .catch((err) => {
        customToast('error', err.response.message || 'Something went wrong');
      });
  }, []);

  useEffect(() => {
    if (isLoading && freightIndex.length > 0 && avgFuelPrice.length > 0) {
      let graphData = [
        [
          'x',
          'Freight index',
          { role: 'tooltip', type: 'string', p: { html: true } },
          'Avg. fuel rate in Top 4 Metro cities (₹/Ltr)',
          { role: 'annotation', type: 'string' },
          { role: 'tooltip', type: 'string', p: { html: true } }
        ]
      ];
      let date = new Date();
      for (let i = 11; i >= 0; i--) {
        let row = [];
        if (
          freightIndex[i].value && freightIndex[i].value > 0 &&
          avgFuelPrice[i].value && avgFuelPrice[i].value > 0
        ) {
          date = new Date(freightIndex[i].year, freightIndex[i].month - 1);
          row.push(date);
          row.push(freightIndex[i].value);
          row.push(
            createCustomTooltip(
              'Freight Index:',
              date.toLocaleString('en-US', { month: 'short', year: 'numeric' }),
              freightIndex[i].value
            )
          );
          row.push(avgFuelPrice[i].value);
          row.push(`₹ ${avgFuelPrice[i].value}`);
          row.push(
            createCustomTooltip(
              'Avg. Fuel Price:',
              date.toLocaleString('en-US', { month: 'short', year: 'numeric' }),
              `₹${avgFuelPrice[i].value}`
            )
          );
          graphData.push(row);
        }
      }

      setData(graphData);
      setIsLoading(false);
    }
  }, [isLoading, freightIndex, avgFuelPrice]);

  return (
    <Container >
      <GraphWrapper>
        <GraphTitle>Freight Index</GraphTitle>

        <div id='chart'>
          {isLoading === false && data.length > 1 ? (
            <Chart
              chartType='ComboChart'
              width='100%'
              height='280px'
              data={data}
              options={options}
            />
          ) : (
            <div style={{ height: '300px' }}>No data available</div>
          )}
        </div>
      </GraphWrapper>
      <DownloadGraphWrapper>
        <button onClick={handleDownloadChart}>Download Chart</button>
      </DownloadGraphWrapper>
    </Container>
  );
};

export default FreightIndexGraph;
