import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import Ticker from 'react-ticker';
import styled from 'styled-components';

const TickerWrapper = styled.div`
  // position: absolute;
  // width: 100%;
  width: auto;
  height: 30px;
  z-index: 1;
  color: #fff;
  background-color: rgb(35, 35, 35);
`;

const TickerField = styled.div`
  // position: fixed;
  // top: 50px;
  left: 0;
  /* margin-top: 5px; */
  // width: 100%;
  width: auto;
  height: 30px;
  background-color: rgb(35, 35, 35);
  opacity: 0.85;
  text-rendering: optimizeLegibility;
`;

const TickerFieldText = styled.p`
  // line-height: 50px;
  width: fit-content;
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
  white-space: nowrap;
  margin-left: 3rem;
  margin-top: 3px;
  margin-bottom: 5px;
  /* top: 0; */
  a {
    /* margin-top: 10px; */
    /* height: 30px; */
    vertical-align: middle;
    margin-left: 30px;
    span {
      margin-left: 2px;
      span {
        margin-left: 3px;
      }
    }
  }
`;

const RateTicker = ({ nfiHubPriceList }) => {
  const GetRatesData = () => {
    const [rates, setRates] = useState([]);

    useEffect(() => {
      let mounted = true;
      const getRates = () => {
        setRates(nfiHubPriceList || []);
      };
      getRates();
      return () => {
        mounted = false;
      };
    }, []);
    return rates && rates.length > 0 ? (
      <TickerFieldText>
        {rates.map((items, index) => {
          const { originCity, destinationCity, currentPrice, percentageChange } = items;
          let gain = percentageChange === null || (percentageChange && percentageChange >= 0) ? true : false;
          let percentage = (percentageChange && Math.round(Math.abs(percentageChange) * 100) / 100) || 0;
          if (currentPrice !== null) {
            return (
              <a key={originCity + destinationCity} id={index}>
                {`${originCity} - ${destinationCity}`}
                <span> ₹ {currentPrice}</span>
                {/* {percentage > 0 ? (
                  <span>
                    {' '}
                    (
                    <span style={{ color: gain ? '#00B027' : '#E32728' }}>
                      <FontAwesomeIcon size='lg' icon={gain ? faCaretUp : faCaretDown} />
                      {percentage}%
                    </span>
                    ){' '}
                  </span>
                ) : (
                  ''
                )} */}
              </a>
            );
          }
        })}
      </TickerFieldText>
    ) : (
      <TickerFieldText>
        <span style={{ color: '#f4f4f4' }}>Just Waiting for the Feed!</span>
      </TickerFieldText>
    );
  };

  return (
    <TickerWrapper>
      <TickerField>
        <Ticker offset='run-in'>{() => <GetRatesData />}</Ticker>
      </TickerField>
    </TickerWrapper>
  );
};
export default RateTicker;
