import { toast } from 'react-toast';

function customToast(type, message) {
  toast[type](message, {
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    backgroundColor: type === 'success' ? '#38833A' : '#E3382E'
  });
}

export default customToast;
