//Sentry is in global scope. Used gatsby-plugin-sentry

export default function sendErrorLogToSentry(error) {
  if (process.env.GATSBY_ENABLE_SENTRY === 'true') {
    if (typeof window !== 'undefined') {
      window.Sentry.withScope(() => {
        window.Sentry.captureException(error);
      });
    }
  }
  return;
}
