import React, { useState } from 'react';
import { Dialog, DialogWrapper } from '../components-pure/Dialog';
import { CrossIcon } from '../custom-icons';
import styled from 'styled-components';
import { device } from '../constants/device';
import SecHeading from '../components/SectionHeading/SectionHeading';
import { userServices } from '../services';
import customToast from '../utils/customToast';

const ModalStyled = styled(Dialog)`
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  overflow-y: auto;
  background-color: white;
  label {
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
    cite {
      color: #969696;
    }
  }
  span {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: #e3383a;
    margin-right: 10px;
  }
  .content {
    padding: 10px 0px 30px 0;
    color: #969696;
    font-size: 14px;
  }
  .submitSection {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
  .submit {
    padding: 10px;
    background: #e33232;
    font-weight: bold;
    color: white;
    border-radius: 36px;
    width: 60%;
    margin: 10px 0px;
    :hover {
      background: #f54040;
      cursor: pointer;
    }
  }
  @media ${device.tablet} {
  }
`;

const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  color: #969696;
`;
const CustomInput = styled.input`
  padding: 10px;
  width: 140px;
  border: 1px solid #969696;
  border-radius: 4px;
`;

const SearchRateModal = ({ show, handleClose, data }) => {
  const [rate, setRate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleRateChange = (e) => {
    setRate(e.target.value);
    if (e.target.value.length > 0) {
      setError('');
    }
  };

  const handleSubmit = async () => {
    if (rate === '') {
      setError('Rate is required');
    } else {
      setIsLoading(true);
      await userServices
        .postPriceForSelectedOptions(data.refNo, rate)
        .then((res) => {
          handleClose();
          customToast('success', 'Rate updated successfully');
        })
        .catch((err) => {
          customToast('error', 'Something went wrong');
        });
      setIsLoading(false);
    }
  };
  return (
    <DialogWrapper>
      <ModalStyled>
        <SecHeading>Do you have different rates for this lane?</SecHeading>
        <div className='content'>
          NFI is an open source data portal based on a proprietary algorithm that collects inputs from multiple data
          sources to give out the most accurate spot rates. If you notice any discrepancy in the rates, please submit
          the ongoing rates below to help us increase the accuracy of NFI.
        </div>
        <SecHeading>Submit your rates here</SecHeading>
        <FormGroup>
          <CustomInput disabled placeholder='Origin' value={data.origin} />
          <CustomInput disabled placeholder='Destination' value={data.destination} />
          <CustomInput type='number' placeholder='Rates' value={rate} onChange={handleRateChange} />
        </FormGroup>
        {error !== '' && <span>{error}</span>}
        <div className='submitSection'>
          <button className='submit' onClick={handleSubmit} disabled={isLoading ? true : false}>
            Submit
          </button>
        </div>
        <cite onClick={handleClose} className='closeIcon'>
          <CrossIcon />
        </cite>
      </ModalStyled>
    </DialogWrapper>
  );
};

export default SearchRateModal;
