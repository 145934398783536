import styled from 'styled-components';
import { Dialog } from '../components-pure/Dialog';
import { device } from '../constants/device';

const LoginScreen = styled(Dialog)`
  flex-grow: 1;
  flex-direction: column;
  max-width: 450px;
  background-color: white;
  color: #2a2a2a;
  position: relative;
  overflow-y: auto;
  p.head {
    font-size: 18px;
    margin-bottom: 40px;
    font-weight: 700;
    small {
      display: block;
      font-size: 14px;
      color: #969696;
    }
  }
  label {
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
    cite {
      color: #969696;
    }
  }
  .tnc {
    margin: 20px 20px 0 0;
    font-size: 11px;
    a {
      color: #000;
      text-decoration: none;
      font-weight: bold;
    }
  }
  content {
    display: block;
    font-size: 14px;
    margin-top: 16px;
    span {
      display: inline-block;
      vertical-align: middle;
      font-weight: 400;
      padding-right: 5px;
    }
    em {
      font-weight: bold;
      font-style: normal;
      white-space: nowrap;
    }
    .editNo {
      cursor: pointer;
      color: #e33232;
      margin-left: 10px;
    }
  }
  @media ${device.tablet} {
    p.head {
      margin-bottom: 30px;
    }
    .tnc {
      margin-top: 10px;
    }
  }
`;

const LoginFormGroup = styled.div`
  margin-top: 20px;
  position: relative;
  & > p {
    position: relative;
  }
  .prefix {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 34px;
    height: 34px;
    font-size: 14px;
    background: #eeeeee;
    border-right: 1px solid #979797;
    display: inline-block;
    line-height: 35px;
    text-align: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    & + input {
      padding-left: 50px;
    }
  }
  input {
    width: 100%;
    border: 1px solid #969696;
    border-radius: 4px;
    background: none;
    font-size: 15px;
    height: 36px;
    padding: 0 16px;
    &:focus {
      outline: none;
    }
  }
  &.error {
    em {
      font-size: 12px;
      color: #e3383a;
      margin-top: 6px;
      display: block;
      font-style: normal;
    }
    &.hide {
      em {
        visibility: hidden;
      }
    }
    &.show {
      em {
        visibility: visible;
      }
      input {
        border-color: #e3383a;
      }
      .prefix {
        border-color: #e3383a;
      }
    }
  }
  .info {
    cursor: pointer;
    color: #969696;
    right: 16px;
    top: 10px;
    position: absolute;
  }
  @media ${device.tablet} {
    margin-top: 15px;
  }
`;

export { LoginScreen, LoginFormGroup };
