import styled from 'styled-components';
import { device } from '../constants/device';

const DialogWrapper = styled.div`
  background: rgba(90, 90, 90, 0.6);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dialog = styled.div`
  background: white;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-height: 90%;
  margin: 5%;
  position: relative;
  .closeIcon {
    position: absolute;
    right: 0;
    top: 0;
    color: #969696;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    z-index: 2;
    &:hover {
      background: #d8d8d8;
      color: #e3383a;
    }
  }
  @media ${device.tablet} {
    padding: 20px;
    overflow: auto;
  }
`;

export { DialogWrapper, Dialog };
