import { Component } from 'react';
import PropTypes from 'prop-types';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasCaught: false };
  }

  componentDidCatch(err) {
    this.setState({ hasCaught: true });
    console.error('error caught by boundary', err);
    if (this.props.handleError) {
      this.props.handleError(err);
    }
  }

  render() {
    if (this.state.hasCaught) {
      return this.props.showOnError;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  handleError: PropTypes.func,
  children: PropTypes.array,
  showOnError: PropTypes.object
};
