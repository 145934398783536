import styled from 'styled-components';
import PrimaryCta from '@bit/rivigo.ui.primary-cta';

const RfiCta = styled(PrimaryCta)`
  &&& {
    background: #e3383a;
    border: 1px solid #e3383a;
    height: 36px;
    padding: 0 15px;
    &:hover {
      background: #e22727;
    }
    &:disabled {
      border: 1px solid #c0c0c0;
    }
  }
`;

const SecCta = styled(RfiCta)`
  &&& {
    padding: 0 10px;
    background: none;
    border: 1px solid #676767;
    color: #676767;
    height: 28px;
  }
`;
export { RfiCta, SecCta };
