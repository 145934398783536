import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight,
  faCircle,
  faLock,
  faInfoCircle,
  faCheckCircle,
  faTimes,
  faDownload,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';

import RivigoIcon from './RivigoIcon';
import FacebookIcon from './FacebookIcon';
import LinkedinIcon from './LinkedinIcon';
import TwitterIcon from './TwitterIcon';
import WhatsappIcon from './WhatsappIcon';
import SendIcon from './SendIcon';
import OpenTruckIcon from './OpenTruckIcon';
import TrailerTruckIcon from './TrailerTruckIcon';
import ContainerTruckIcon from './ContainerTruckIcon';
import InstagramIcon from './InstagramIcon';
import YoutubeIcon from './YoutubeIcon';

library.add(
  InstagramIcon,
  YoutubeIcon,
  RivigoIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  SendIcon,
  OpenTruckIcon,
  TrailerTruckIcon,
  ContainerTruckIcon,
  faChevronRight,
  faCircle,
  faLock,
  faInfoCircle,
  faCheckCircle,
  faTimes,
  faDownload,
  faEnvelope
);

function ChevronIcon(props) {
  return <Icon {...props} icon='chevron-right' />;
}
function CircleIcon(props) {
  return <Icon {...props} icon='circle' />;
}
function LockIcon(props) {
  return <Icon {...props} icon='lock' />;
}
function InfoIcon(props) {
  return <Icon {...props} icon='info-circle' />;
}
function TickCircleIcon(props) {
  return <Icon {...props} icon='check-circle' />;
}
function CrossIcon(props) {
  return <Icon {...props} icon='times' />;
}
function DownloadIcon(props) {
  return <Icon {...props} icon='download' />;
}
function MailIcon(props) {
  return <Icon {...props} icon='envelope' />;
}

export {
  RivigoIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  SendIcon,
  OpenTruckIcon,
  TrailerTruckIcon,
  ContainerTruckIcon,
  ChevronIcon,
  CircleIcon,
  LockIcon,
  InfoIcon,
  TickCircleIcon,
  CrossIcon,
  DownloadIcon,
  MailIcon,
  InstagramIcon,
  YoutubeIcon
};
