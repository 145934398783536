import React, { Component } from 'react';
import PropTypes from 'prop-types';
import userServices from '../../services/userServices';
import Login from './Login';
import customToast from '../../utils/customToast';

class LoginWrapper extends Component {
  constructor() {
    super();
    this.state = {
      sendOtpXhrIsInProgress: false,
      verifyOtpXhrIsInProgress: false,
      otpIsSent: false,
      otpIsVerified: false,
      phoneNumber: '',
      email: '',
      name: '',
      otp: '',
      phoneNumberIsValid: true,
      otpIsValid: true,
      phoneNumberErrMsg: '',
      otpErrMsg: '',
      emailErrMsg: '',
      isNewUserRegistration: false
    };
  }

  render() {
    const { sendOtpXhrIsInProgress, verifyOtpXhrIsInProgress } = this.state;
    return (
      <Login
        onSendOtpClick={this.onSendOtpClick}
        otpIsSent={this.state.otpIsSent}
        sendOtpXhrIsInProgress={sendOtpXhrIsInProgress}
        verifyOtpXhrIsInProgress={verifyOtpXhrIsInProgress}
        onVerifyOtpClick={this.verifyOtpClick}
        onEditPhoneNumberClick={this.onEditPhoneNumberClick}
        onCloseBtnClick={this.props.onCloseBtnClick}
        otpErrMsg={this.state.otpErrMsg}
        phoneNumberErrMsg={this.state.phoneNumberErrMsg}
        resetErrMsg={this.resetErrMsg}
        isNewUserRegistration={this.state.isNewUserRegistration}
      />
    );
  }

  resetErrMsg = () => {
    this.setState({
      otpErrMsg: '',
      phoneNumberErrMsg: '',
      emailErrMsg: ''
    });
  };

  onSendOtpClick = (phoneNumber) => {
    this.setState({
      sendOtpXhrIsInProgress: true
    });
    this.sendOtp(phoneNumber);
  };

  sendOtp = async (phoneNumber) => {
    const updateObj = {
      sendOtpXhrIsInProgress: false
    };

    try {
      const response = await userServices.sendOtp(phoneNumber);
      if (response && response.status === 'SUCCESS') {
        userServices.sendAnalyticsEvents({
          category: 'Login',
          action: 'Clicks',
          label: 'SENDOTP -  Confirm'
        });
        updateObj.otpIsSent = true;
        updateObj.phoneNumberErrMsg = '';
        updateObj.isNewUserRegistration = response.additionalDetails.isNewUserRegistration;
      } else if (response && response.errorMessage) {
        customToast('error', response.errorMessage || 'Something went wrong');
        updateObj.phoneNumberErrMsg = response.errorMessage;
        userServices.sendAnalyticsEvents({
          category: 'Login',
          action: 'Failure',
          label: `SENDOTP - Confirm, Error: ${response.errorMessage}`
        });
      }
    } catch (err) {
      userServices.sendAnalyticsEvents({
        category: 'Login',
        action: 'Failure',
        label: `SENDOTP - Confirm, Error: ${err}`
      });
    }

    this.setState(updateObj);
  };

  verifyOtpClick = (phoneNumber, otp, name, email, userTypeSelected, userLoginConfig) => {
    this.setState({
      verifyOtpXhrIsInProgress: true
    });
    this.verifyOtp(phoneNumber, otp, name, email, userTypeSelected, userLoginConfig);
  };

  verifyOtp = async (phoneNumber, otp, name, email, userTypeSelected, userLoginConfig) => {
    const updateObj = {
      verifyOtpXhrIsInProgress: false
    };
    try {
      const response = await userServices.verifyOtp(phoneNumber, otp, name, email, userTypeSelected);
      if (response && response.status === 'SUCCESS') {
        userServices.sendAnalyticsEvents({
          category: 'Login',
          action: 'Clicks',
          label: 'VerifyOTP -  Confirm'
        });
        if (userTypeSelected !== undefined) {
          await userServices.submitSurveyConfig(userTypeSelected, phoneNumber);
        }
        this.props.onOtpVerificationSuccess(response);
        updateObj.otpIsVerified = true;
        updateObj.otpErrMsg = '';
        this.props.onCloseBtnClick();
      } else if (response && response.status === 'FAILURE') {
        customToast('error', response.errorMessage || 'Something went wrong');
        userServices.sendAnalyticsEvents({
          category: 'Login',
          action: 'Failure',
          label: `VerifyOTP - Confirm, Error: ${response.errorMessage}`
        });
        updateObj.otpErrMsg = response.errorMessage;
      }
    } catch (err) {
      userServices.sendAnalyticsEvents({
        category: 'Login',
        action: 'Failure',
        label: `VerifyOTP - Confirm, Error: ${err}`
      });
      customToast('error', 'Something went wrong');
    }
    this.setState(updateObj);
  };

  onResendOtpClick = (phoneNumber, otp) => {
    this.verifyOtpClick(phoneNumber, otp);
  };

  onEditPhoneNumberClick = () => {
    this.setState({
      otpIsSent: false,
      otp: ''
    });
  };
}

LoginWrapper.propTypes = {
  onOtpVerificationSuccess: PropTypes.func,
  onCloseBtnClick: PropTypes.func.isRequired
};

export default LoginWrapper;
