import spotPriceData from '../data/ratesDataNew.json';

function getConstantValuesFromJson() {
  let TRUCK_TYPE, TRUCK_TYPE_GROUP, SOURCE_ZONE, DISTANCE_BAND;
  Object.keys(spotPriceData[0]).forEach((key, index) => {
    if (index === 0) {
      TRUCK_TYPE = key;
    } else if (index === 1) {
      TRUCK_TYPE_GROUP = key;
    } else if (index === 2) {
      SOURCE_ZONE = key;
    } else if (index === 3) {
      DISTANCE_BAND = key;
    }
  });

  let availableDistanceBands = ['all'],
    availableSourceZones = ['all'],
    availableTruckType = ['all'];

  spotPriceData.forEach((spotPriceObject) => {
    if (availableDistanceBands.indexOf(spotPriceObject[DISTANCE_BAND]) === -1) {
      availableDistanceBands.push(spotPriceObject[DISTANCE_BAND]);
    }
    if (availableSourceZones.indexOf(spotPriceObject[SOURCE_ZONE]) === -1) {
      availableSourceZones.push(spotPriceObject[SOURCE_ZONE]);
    }
    if (availableTruckType.indexOf(spotPriceObject[TRUCK_TYPE]) === -1) {
      availableTruckType.push(spotPriceObject[TRUCK_TYPE]);
    }
  });

  let availableTruckTypeGroups = [['all']];

  availableTruckType.forEach((truckType, index) => {
    if (index !== 0) {
      spotPriceData.forEach((spotPriceObject) => {
        if (spotPriceObject[TRUCK_TYPE] === truckType) {
          if (!availableTruckTypeGroups[index]) {
            availableTruckTypeGroups[index] = [];
          }
          if (availableTruckTypeGroups[index].indexOf(spotPriceObject[TRUCK_TYPE_GROUP]) === -1) {
            availableTruckTypeGroups[index].push(spotPriceObject[TRUCK_TYPE_GROUP]);
          }
        }
      });
    }
  });

  [availableTruckType[1], availableTruckType[2]] = [availableTruckType[2], availableTruckType[1]];
  [availableTruckTypeGroups[1], availableTruckTypeGroups[2]] = [
    availableTruckTypeGroups[2],
    availableTruckTypeGroups[1]
  ];
  return {
    TRUCK_TYPE,
    TRUCK_TYPE_GROUP,
    SOURCE_ZONE,
    DISTANCE_BAND,
    AVAILABLE_DISTANCE: availableDistanceBands,
    AVAILABLE_SOURCE_ZONES: availableSourceZones,
    AVAILABLE_TRUCK_TYPE: availableTruckType,
    AVAILABLE_TRUCK_TYPE_GROUPS: availableTruckTypeGroups
  };
}

const constantValues = getConstantValuesFromJson();

export default {
  ALL: 'all',
  TRUCK_TYPE: constantValues.TRUCK_TYPE,
  TRUCK_TYPE_GROUP: constantValues.TRUCK_TYPE_GROUP,
  SOURCE_ZONE: constantValues.SOURCE_ZONE,
  DISTANCE_BAND: constantValues.DISTANCE_BAND,
  AVAILABLE_SOURCE_ZONES: constantValues.AVAILABLE_SOURCE_ZONES,
  AVAILABLE_DISTANCE: constantValues.AVAILABLE_DISTANCE,
  AVAILABLE_TRUCK_TYPE: constantValues.AVAILABLE_TRUCK_TYPE,
  AVAILABLE_TRUCK_TYPE_GROUPS: constantValues.AVAILABLE_TRUCK_TYPE_GROUPS,
  WEIGHT: 'Weight',
  NORTH: 'North',
  SOUTH: 'South',
  WEST: 'West',
  EAST: 'East',
  ALL_INDIA: 'All-India',
  WEIGHT_SCORE: 'Weight Score',
  ODVT: 'ODVT',
  LANE_ID: 'Lane ID',
  TRUCK_ID: 'truck_id',
  VEHICLE_TYPE_ID: 'vehicle_type_id',
  VEHICLE_LENGTH_ID: 'vehicle_length_id',
  VEHICLE_WEIGHT_ID: 'vehicle_weight_id',
  MONTHS: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  tyre10: '10tyre',
  formattedTyre10: '10 tyre',
  formattedTyre12: '12 tyre',
  tyre12: '12tyre',
  tyre14: '14+ tyre',
  formattedTyre14: '14, 18, 22 tyre',
  formattedTyre19ft: '6 tyre',
  tyre19ft: '19 ft',
  MONTH_CONSTANT: 8
};
