import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import SearchRateModal from './SearchRateModal';
import AutoComplete from './AutoComplete';
import { userServices } from '../services';
import customToast from '../utils/customToast';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Heading = styled.div`
  width: 100%;
  display: flex;
  padding: 15px 0;
  h2 {
    padding: 15px 0;
    font-size: 14px;
  }
`;
const Card = styled.div`
  width: 100%;
  background: #e6e6e6;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
`;
const CardData = styled.div`
  color: #858383;
  h2 {
    font-size: 16px;
  }
`;
const CustomInput = styled.input`
  padding: 10px;
  border-radius: 8px;
  margin: 10px 0px;
  width: 100%;
  ::placeholder {
    color: #858383;
  }
`;
const CustomButton = styled.button`
  padding: 10px;
  background: #e33232;
  color: white;
  border-radius: 56px;
  width: 100%;
  font-weight: bold;
  margin: 10px 0px;
  :hover {
    background: #f54040;
    cursor: pointer;
  }
  &.diffRatesBtn {
    padding: 5px 10px;
    margin-top: 5px;
  }
`;

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  justify-content: space-between;
  button.backBtn {
    margin: 10px 0px;
    padding: 10px;
    width: 50px;
    background: white;
    border-radius: 24px;
    cursor: pointer;
    color: #e33232;
  }
  button.rateBtn {
    width: calc(100% - 55px);
    padding: 10px;
    margin: 10px 0px;
    background: white;
    color: #e33232;
    font-size: 16px;
    font-weight: bold;
    border-radius: 24px;
  }
`;

const SearchRates = ({ isUserLoggedIn }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showRate, setShowRate] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => {
    if (isUserLoggedIn) {
      setModalData({
        origin: originPlaceId.description,
        destination: destinationPlaceId.description,
        refNo: refNo
      });
      setModalShow(true);
    } else {
      customToast('error', 'Please login to continue');
    }
  };

  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [originPlaceId, setOriginPlaceId] = useState();
  const [destinationPlaceId, SetDestinationPlaceId] = useState();
  const [rate, setRate] = useState('');
  const [refNo, setRefNo] = useState('');
  const handleOriginChange = (value) => {
    setOriginPlaceId(value);
    userServices
      .getLatLongForPlaceID(value.placeId)
      .then((res) => {
        setOrigin(res);
      })
      .catch((err) => {
        customToast('error', 'Something went wrong');
      });
  };

  const handleDestinationChange = (value) => {
    SetDestinationPlaceId(value);
    userServices
      .getLatLongForPlaceID(value.placeId)
      .then((res) => {
        setDestination(res);
      })
      .catch((err) => {
        customToast('error', 'Something went wrong');
      });
  };

  const handleGetRate = async () => {
    if (origin && destination) {
      setIsLoading(true);
      let response = userServices.getPriceForSelectedOptions(origin, destination);
      response
        .then((res) => {
          if (res.refNo && res.totalPrice) {
            setRefNo(res.refNo);
            setRate(res.totalPrice);
            setShowRate(true);
          } else if (res.code == 2) {
            let error = res.message;
            customToast('error', error || 'Something went wrong');
          }
          setIsLoading(false);
        })
        .catch((err) => {
          customToast('error', 'Something went wrong');
          setIsLoading(false);
        });
    }
  };

  return (
    <Container>
      <Heading>
        <h2>All Lanes</h2>
      </Heading>
      <Card>
        <CardData>
          <h2>Search Rates</h2>
          <AutoComplete placeholder='Select loading point' handleChange={handleOriginChange} />
          <AutoComplete placeholder='Select unloading point' handleChange={handleDestinationChange} />
          {showRate ? (
            <>
              <ButtonsGroup>
                <button className='backBtn' onClick={(e) => setShowRate(false)}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button className='rateBtn'>₹ {rate}</button>
              </ButtonsGroup>
              <CustomButton className='diffRatesBtn' onClick={handleShow} disabled={isLoading}>
                Have different rates?
              </CustomButton>
            </>
          ) : (
            <CustomButton onClick={handleGetRate} disabled={isLoading}>
              Get Today's Rate
            </CustomButton>
          )}
        </CardData>
      </Card>
      {modalShow && <SearchRateModal show={modalShow} handleClose={handleClose} data={modalData} />}
    </Container>
  );
};

export default SearchRates;
