import React, { Component } from 'react';
import iffun from '@bit/rivigo.ui.utils.iffun';
import Spinner from '@bit/rivigo.ui.spinner';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Timer from '@bit/rivigo.ui.components.timer';
import { LoginScreen, LoginFormGroup } from '../../components-pure/LoginStyles';
import userServices from '../../services/userServices';
import { RfiCta } from '../PrimaryCTA/PrimaryCTA';
import { CrossIcon } from '../../custom-icons';

const ChipsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  .checkbox {
    display: flex;
    align-items: center;
    margin: 5px 0;
    input {
      appearance: revert;
      width: auto;
      height: auto;
    }
    input:checked {
      background: #e3383a;
      border: 1px solid #e3383a;
      color: #ffffff;
    }
    label {
      margin-bottom: 0;
      vertical-align: middle;
      margin-left: 10px;
      font-size: 14px;
    }
    input[type='radio'] {
      accent-color: #e3383a;
    }
  }
  span {
    padding: 6px 10px;
    border-radius: 22px;
    color: #969696;
    border: 1px solid #dfdfdf;
    font-size: 12px;
    margin: 6px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    font-style: normal;
    text-align: center;
    &.active,
    &:hover {
      background: #2a2a2a;
      border-color: #2a2a2a;
      color: #ffffff;
    }
  }
`;

export default class VerifyOtpScreen extends Component {
  state = {
    userTypeSelected: null,
    userLoginConfig: null
  };

  async componentWillMount() {
    if (this.props.isNewUserRegistration === true) {
      let getLoginConfig = await userServices.getLoginConfig();
      if (getLoginConfig) {
        this.setState({ userLoginConfig: getLoginConfig.userRoleTypeDescriptionMap });
      }
    }
  }

  handleTypeSelect = (type) => {
    userServices.sendAnalyticsEvents({
      category: 'Login',
      action: 'Clicks',
      label: `${this.state.userLoginConfig[type]}`
    });
    this.setState({ userTypeSelected: type });
  };

  render() {
    const {
      phoneNumber,
      onEditPhoneNumberClick,
      otpErrMsgProp,
      otpIsValid,
      otp,
      otpLength,
      onOtpChange,
      onKeyDown,
      otpErrMsg,
      timerIsOn,
      timeIsOver,
      onResendOtpClick,
      onVerifyOtpClick,
      onCloseBtnClick,
      verifyOtpXhrIsInProgress,
      isNewUserRegistration
    } = this.props;

    const { userTypeSelected, userLoginConfig } = this.state;

    return (
      <LoginScreen>
        {iffun(
          verifyOtpXhrIsInProgress === true,
          () => (
            <div style={{ width: '100%', minHeight: '450px', position: 'relative' }}>
              <Spinner />
            </div>
          ),
          () => (
            <>
              <p className='head'>
                Please login to continue
                {/* <small>Almost there!</small> */}
                <content>
                  <span>A 4 digit code has been sent to</span>
                  <em>
                    +91-{phoneNumber}
                    <span onClick={onEditPhoneNumberClick} className='editNo'>
                      Edit
                    </span>
                  </em>
                </content>
              </p>

              <LoginFormGroup
                className={iffun(otpErrMsgProp.length > 0 || otpIsValid === false, 'show error', 'hide error')}>
                {/* <label>Enter OTP</label> */}
                <p>
                  <input
                    type='text'
                    value={otp}
                    maxLength={otpLength}
                    onChange={onOtpChange}
                    placeholder='Enter OTP'
                    onKeyDown={onKeyDown}
                    autoFocus
                  />
                </p>
                <em>{otpErrMsgProp || otpErrMsg || 'Error'}</em>
                <small className='info'>
                  {iffun(
                    timerIsOn,
                    <Timer firstText='Resend in ' secondText=' seconds.' time={60} timeIsOver={timeIsOver} />,
                    <div onClick={onResendOtpClick}>RESEND</div>
                  )}
                </small>
              </LoginFormGroup>
              {iffun(
                isNewUserRegistration === true && userLoginConfig !== null,
                () => {
                  return (
                    <LoginFormGroup>
                      <label style={{ fontSize: '14px' }}>
                        What best describes your work? <cite style={{ color: '#e3383a' }}>(required)</cite>
                      </label>
                      <ChipsWrapper>
                        {Object.keys(userLoginConfig).map((type, index) => {
                          let className = '';
                          if (type === userTypeSelected) {
                            className = 'active';
                          }
                          return (
                            // <span onClick={this.handleTypeSelect.bind(this, type)} className={className} key={index}>
                            //   {userLoginConfig[type]}
                            // </span>
                            <div className='checkbox' key={index}>
                              <input
                                id={index}
                                className={className}
                                type='radio'
                                name='userType'
                                value={type}
                                onClick={this.handleTypeSelect.bind(this, type)}
                              />
                              <label htmlFor={index}>{userLoginConfig[type]}</label>
                            </div>
                          );
                        })}
                      </ChipsWrapper>
                    </LoginFormGroup>
                  );
                },
                null
              )}

              <p className='tnc'>
                By continuing, you will agree to our{' '}
                <a href='/Terms_of_use' target='_blank' rel='noopener noreferrer'>
                  Terms of Use
                </a>{' '}
                and{' '}
                <a href='/Privacy_policy' target='_blank' rel='noopener noreferrer'>
                  Privacy Policy
                </a>
              </p>
              <LoginFormGroup>
                <RfiCta
                  disabled={iffun(
                    otp.length === 4 && (userTypeSelected !== null || isNewUserRegistration === false),
                    false,
                    true
                  )}
                  variant='contained'
                  color='primary'
                  style={{ borderRadius: '56px' }}
                  onClick={onVerifyOtpClick.bind(this, userTypeSelected, userLoginConfig)}>
                  Login
                </RfiCta>
              </LoginFormGroup>
              <cite onClick={onCloseBtnClick} className='closeIcon'>
                <CrossIcon />
              </cite>
            </>
          )
        )}
      </LoginScreen>
    );
  }
}

VerifyOtpScreen.propTypes = {
  onCloseBtnClick: PropTypes.func,
  onVerifyOtpClick: PropTypes.func,
  otp: PropTypes.string,
  onResendOtpClick: PropTypes.func,
  timeIsOver: PropTypes.func,
  otpErrMsgProp: PropTypes.string,
  otpErrMsg: PropTypes.string,
  otpLength: PropTypes.number,
  onOtpChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  timerIsOn: PropTypes.bool,
  phoneNumber: PropTypes.string,
  otpIsValid: PropTypes.bool,
  onEditPhoneNumberClick: PropTypes.func,
  verifyOtpXhrIsInProgress: PropTypes.bool,
  isNewUserRegistration: PropTypes.bool
};
