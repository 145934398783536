import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import iffun from '@bit/rivigo.ui.utils.iffun';
import { RfiCta } from '../components/PrimaryCTA/PrimaryCTA';
import { CrossIcon, ChevronIcon } from '../custom-icons';
import { device } from '../constants/device';
import LoginWrapper from './Login/LoginWrapper';
import userServices from '../services/userServices';
import nfiLogo from '../images/Logo-new.png';
import { ToastContainer } from 'react-toast';

const HeaderWrapper = styled.header`
  width: 100%;
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 7;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
`;
const HeaderInner = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  img {
    cursor: pointer;
  }
  .logo {
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    background: #e3383a;
    padding: 5px 10px;
    border-radius: 2px;
  }
  .headerRightPane-m {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  .hemMenu {
    height: 24px;
    width: 24px;
    border-bottom: 2px solid #2a2a2a;
    position: relative;
    cursor: pointer;
    margin-left: 15px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      height: 13px;
      width: 100%;
      border-top: 2px solid;
      border-bottom: 2px solid;
      border-color: #2a2a2a;
      display: inline-block;
    }
  }
  .userInfo {
    position: relative;
    padding: 8px 10px;
    border: 1px solid #e3383a;
    color: #e3383a;
    border-radius: 24px;
    line-height: 1;
    display: inline-block;
    i {
      margin-left: 10px;
    }
    svg {
      transform: rotate(90deg);
      color: #e3383a;
      transition: all 0.3s ease-in-out;
    }
    em {
      display: none;
      position: absolute;
      top: 31px;
      right: 0;
      width: 100%;
      color: #000;
      background: #fff;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
      padding: 10px;
      font-style: normal;
      border-radius: 4px;
    }
    &:hover {
      em {
        display: block;
      }
      svg {
        transform: rotate(-90deg);
      }
    }
    &.hide {
      display: none;
    }
  }
  @media ${device.midDevice2} {
    .closeIcon,
    .headerRightPane-m {
      display: none;
    }
  }
`;

const CustomRfiCta = styled('button')`
  background: #ffffff;
  height: 32px;
  font-weight: bold;
  color: #e3383a;
  font-size: 14px;
  border: 1px solid #e3383a;
  cursor: pointer;
  padding: 0 20px;
  border-radius: 56px;
  &:hover {
    background: #e3383a;
    color: #ffffff;
  }
`;

const TopNav = styled.nav`
  ul {
    display: flex;
    align-items: center;
  }
  li {
    list-style: none;
    margin: 0 0 0 30px;
    cursor: pointer;
    &:first-of-type {
      margin-left: 0;
    }
  }
  a {
    color: #2a2a2a;
    font-size: 14px;
    &:hover {
      color: #e3383a;
    }
  }

  @media ${device.tablet} {
    position: fixed;
    right: -100%;
    top: 0;
    bottom: 0;
    width: 100%;
    transition: all 0.4s ease-in-out;
    &:before {
      content: '';
      background: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0;
      transition: opacity 0.4s ease-out;
      transition-delay: 0.25s;
    }
    &.active {
      right: 0;
      &:before {
        opacity: 0.8;
      }
    }
    ul {
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding: 48px 0 20px;
      background: #ffffff;
      width: 80%;
      max-width: 400px;
      margin-left: auto;
      z-index: 1;
      position: relative;
    }
    li {
      width: 100%;
      margin-left: 0;
      padding: 14px 16px;
      font-size: 16px;
      font-weight: 700;
      border-top: 1px solid #d8d8d8;
    }
    .closeIcon {
      width: 40px;
      height: 40px;
      display: inline-block;
      text-align: center;
      line-height: 40px;
      color: #2a2a2a;
      font-size: 20px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }
  }
`;

class Header extends Component {
  state = {
    showLoginPopup: false,
    hemMenuClass: ''
  };

  handleLoginClick = async () => {
    userServices.sendAnalyticsEvents({
      category: 'Login',
      action: 'Clicks',
      label: 'Login Button'
    });

    if (this.props.isUserLoggedIn === true) {
      await userServices.revokeAuthToken();
      window.localStorage.removeItem('sessionToken');
      window.localStorage.removeItem('userMobile');
      window.location = '/';
    } else {
      this.setState({ showLoginPopup: true });
    }
  };

  handleCloseBtnClick = () => {
    this.setState({ showLoginPopup: false });
  };

  onOtpVerificationSuccess = (res) => {
    //TODO set actual token
    window.localStorage.setItem('sessionToken', res.response.access_token);
    window.location = '/';
  };

  headerButtonsClick = (location) => {
    userServices.sendAnalyticsEvents({
      category: 'Header',
      action: 'Clicks',
      label: `${location}`
    });
    window.location = `/${location}`;
  };

  render() {
    const { showLoginPopup } = this.state;
    return (
      <HeaderWrapper>
        <HeaderInner>
          <img onClick={() => (window.location = '/')} style={{ width: '120px' }} src={nfiLogo} alt='' />
          <div className='headerRightPane-m'>
            {iffun(
              this.props.isUserLoggedIn === true,
              () => (
                <p className='userInfo m-user'>
                  {this.props.userMobile}
                  <i>
                    <ChevronIcon />
                  </i>
                  <em onClick={this.handleLoginClick}>Logout</em>
                </p>
              ),
              null
            )}
            <span onClick={() => this.setState({ hemMenuClass: 'active' })} className='hemMenu' />
          </div>
          <TopNav className={this.state.hemMenuClass}>
            <ul>
              {/* <li>
                <a onClick={() => this.headerButtonsClick('insights')}>Insights</a>
              </li> */}
              <li>
                <a onClick={() => this.headerButtonsClick('faqs')}>FAQs</a>
              </li>
              <li>
                <a onClick={() => this.headerButtonsClick('contact')}>Contact us</a>
              </li>
              {iffun(
                this.props.isUserLoggedIn === true,
                () => (
                  <li>
                    <p className='userInfo'>
                      +91{this.props.userMobile}
                      <i>
                        <ChevronIcon />
                      </i>
                      <em onClick={this.handleLoginClick}>Logout</em>
                    </p>
                  </li>
                ),
                () => (
                  <li id='loginButton' onClick={this.handleLoginClick}>
                    <CustomRfiCta>LOGIN</CustomRfiCta>
                  </li>
                )
              )}
            </ul>
            <span className='closeIcon'>
              <CrossIcon onClick={() => this.setState({ hemMenuClass: '' })} />
            </span>
          </TopNav>
        </HeaderInner>
        {iffun(
          showLoginPopup === true,
          () => (
            <LoginWrapper
              onCloseBtnClick={this.handleCloseBtnClick}
              onOtpVerificationSuccess={this.onOtpVerificationSuccess}
            />
          ),
          null
        )}
        <ToastContainer position='bottom-center' delay={3000} />
      </HeaderWrapper>
    );
  }
}

Header.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  userMobile: PropTypes.string
};

export default Header;
