import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

const iconName = 'send-icon';
function SendIcon(props) {
  return <Icon {...props} icon={iconName} />;
}
SendIcon.def = {
  prefix: 'fas',
  iconName,
  icon: [
    535.5,
    535.5,
    [],
    '',
    'M438.731,209.463l-416-192c-6.624-3.008-14.528-1.216-19.136,4.48c-4.64,5.696-4.8,13.792-0.384,19.648l136.8,182.4 l-136.8,182.4c-4.416,5.856-4.256,13.984,0.352,19.648c3.104,3.872,7.744,5.952,12.448,5.952c2.272,0,4.544-0.48,6.688-1.472 l416-192c5.696-2.624,9.312-8.288,9.312-14.528S444.395,212.087,438.731,209.463z'
  ]
};
export default SendIcon;
