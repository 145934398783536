import React, { Component } from 'react';
import iffun from '@bit/rivigo.ui.utils.iffun';
import Spinner from '@bit/rivigo.ui.spinner';
import PropTypes from 'prop-types';
import { LoginScreen, LoginFormGroup } from '../../components-pure/LoginStyles';
import { RfiCta } from '../PrimaryCTA/PrimaryCTA';
import { CrossIcon } from '../../custom-icons';

export default class SendOtpScreen extends Component {
  render() {
    return (
      <LoginScreen>
        {iffun(
          this.props.sendOtpXhrIsInProgress === true,
          () => (
            <div style={{ width: '100%', minHeight: '236px', position: 'relative' }}>
              <Spinner />
            </div>
          ),
          () => (
            <>
              {' '}
              <p className='head'>Please login to continue</p>
              <LoginFormGroup>
                <p>
                  <input type='text' onChange={this.props.onNameChange} value={this.props.name} placeholder='Name' />
                </p>
              </LoginFormGroup>
              <LoginFormGroup className={iffun(this.props.emailErrMsg !== '', 'show error', 'hide error')}>
                <p>
                  <input
                    type='email'
                    onChange={this.props.onEmailChange}
                    value={this.props.email}
                    placeholder='Email ID'
                  />
                </p>
                {iffun(
                  this.props.emailErrMsg !== '',
                  () => (
                    <em>{this.props.emailErrMsg || 'Error'}</em>
                  ),
                  null
                )}
              </LoginFormGroup>
              <LoginFormGroup
                className={iffun(
                  this.props.phoneNumberErrMsgProp.length > 0 || this.props.phoneNumberIsValid === false,
                  'show error',
                  'hide error'
                )}>
                <p>
                  {/* <span className='prefix'>+ 91</span> */}
                  <input
                    type='text'
                    onChange={this.props.onPhoneNumberChange}
                    maxLength={10}
                    value={this.props.phoneNumber}
                    placeholder='Contact number'
                    onKeyDown={this.props.onKeyDown}
                    autoFocus
                  />
                </p>
                <em>
                  {this.props.phoneNumberErrMsgProp ||
                    this.props.phoneNumberErrMsg ||
                    this.props.emailErrMsg ||
                    'Error'}
                </em>
              </LoginFormGroup>
              <p className='tnc'>
                By continuing, you will agree to our{' '}
                <a href='/Terms_of_use' target='_blank' rel='noopener noreferrer'>
                  Terms of Use
                </a>{' '}
                and{' '}
                <a href='/Privacy_policy' target='_blank' rel='noopener noreferrer'>
                  Privacy Policy
                </a>
              </p>
              <LoginFormGroup>
                <RfiCta
                  disabled={iffun(
                    this.props.phoneNumber.length === 10 && this.props.email !== '' && this.props.name !== '',
                    false,
                    true
                  )}
                  variant='contained'
                  color='primary'
                  style={{ borderRadius: '55px' }}
                  onClick={this.props.onSendOtpClick}>
                  Send Otp
                </RfiCta>
              </LoginFormGroup>
              <cite onClick={this.props.onCloseBtnClick} className='closeIcon'>
                <CrossIcon />
              </cite>
            </>
          )
        )}
      </LoginScreen>
    );
  }
}

SendOtpScreen.propTypes = {
  onCloseBtnClick: PropTypes.func,
  phoneNumberErrMsgProp: PropTypes.string,
  phoneNumberIsValid: PropTypes.bool,
  onPhoneNumberChange: PropTypes.func,
  phoneNumber: PropTypes.string,
  onKeyDown: PropTypes.func,
  phoneNumberErrMsg: PropTypes.string,
  onSendOtpClick: PropTypes.func,
  sendOtpXhrIsInProgress: PropTypes.bool
};
