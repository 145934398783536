import React, { useState } from 'react';
import styled from 'styled-components';
import userServices from '../services/userServices';

const AutoCompleteWrapper = styled.div`
  input {
    padding: 10px;
    border-radius: 8px;
    margin: 10px 0px;
    width: 100%;
    ::placeholder {
      color: #858383;
    }
  }
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  .suggestions {
    border-radius: 8px;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
  }
  .suggestions li {
    padding: 0.5rem;
    background-color: #fff;
  }
  .suggestion-active,
  .suggestions li:hover {
    background-color: #e6e6e6;
    cursor: pointer;
    font-weight: 700;
  }
  .suggestions li:not(:last-of-type) {
    border-bottom: 0.5px solid #a5a4a4;
  }
`;

const AutoComplete = ({ placeholder, handleChange }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState('');
  const onChange = (e) => {
    let userInput = e.target.value;
    if (userInput.length > 2) {
      userServices.getSuggestions(userInput).then((res) => {
        setFilteredSuggestions(res.predictions);
      });
    }
    setInput(e.target.value);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };
  const onClick = (e, value) => {
    setFilteredSuggestions([]);
    setInput(value.description);
    handleChange(value);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };
  const onKeyDown = (key) => {
    if (key.keyCode === 13 || key.keyCode === 9) {
      setInput(filteredSuggestions[activeSuggestionIndex]);
      setFilteredSuggestions([]);
    }
  };

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul className='suggestions'>
        {filteredSuggestions.map((suggestion, index) => {
          let className;
          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = 'suggestion-active';
          }
          return (
            <li className={className} key={index} onClick={(e) => onClick(e, suggestion)}>
              {suggestion.description}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className='no-suggestions'>
        <em>No suggestions, you're on your own!</em>
      </div>
    );
  };

  return (
    <AutoCompleteWrapper>
      <input type='text' onChange={onChange} placeholder={placeholder} onKeyDown={onKeyDown} value={input} />
      {showSuggestions && input.length > 2 && <SuggestionsListComponent />}
    </AutoCompleteWrapper>
  );
};
export default AutoComplete;
