import React, { Component } from 'react';
import PropTypes from 'prop-types';
import iffun from '@bit/rivigo.ui.utils.iffun';
import { DialogWrapper } from '../../components-pure/Dialog';
import SendOtpScreen from './SendOtpScreen';
import VerifyOtpScreen from './VerifyOtpScreen';
import userServices from '../../services/userServices';

export default class Login extends Component {
  state = {
    phoneNumber: '',
    otp: '',
    phoneNumberIsValid: true,
    otpIsValid: true,
    resentOtpIsActivated: false,
    otpErrMsg: `Please enter OTP`,
    phoneNumberErrMsg: `Please enter phone number`,
    timerIsOn: false,
    email: '',
    emailErrMsg: '',
    isEmailValid: true,
    name: ''
  };

  componentWillMount() {
    document.getElementsByTagName('body')[0].classList.add('noScroll');
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].classList.remove('noScroll');
  }

  onPhoneNumberChange = (e) => {
    if (e.target.value.length !== 0 && isNaN(e.target.value) === true) {
      return;
    }
    userServices.sendAnalyticsEvents({
      category: 'Login',
      action: 'DataEntry',
      label: `Phone Number: ${e.target.value}`
    });

    let phoneNumberErrMsg = '';

    if (e.target.value.length === 0) {
      phoneNumberErrMsg = `Phone number can't be empty.`;
    } else if (e.target.value.length < 10) {
      phoneNumberErrMsg = `Enter 10-digit phone number.`;
    }

    this.setState(
      {
        phoneNumber: e.target.value,
        phoneNumberIsValid: true,
        phoneNumberErrMsg
      },
      () => {
        this.props.resetErrMsg();
      }
    );
  };

  onEmailChange = (e) => {
    let emailErrMsg = '';
    // if (e.target.value.length === 0 || (e.target.value.length > 0 && e.target.value.indexOf('@') === -1)) {
    //   emailErrMsg = 'Enter valid email id.';
    // }
    this.setState(
      {
        email: e.target.value,
        isEmailValid: true,
        emailErrMsg
      },
      () => {
        this.props.resetErrMsg();
      }
    );
  };

  onNameChange = (e) => {
    this.setState({
      name: e.target.value
    });
  };

  onOtpChange = (e) => {
    if (e.target.value.length !== 0 && isNaN(e.target.value) === true) {
      return;
    }
    let otpErrMsg = '';

    if (e.target.value.length === 0) {
      otpErrMsg = `OTP can't be empty.`;
    } else if (e.target.value.length < this.props.otpLength) {
      otpErrMsg = `Enter ${this.props.otpLength}-digit OTP.`;
    }

    this.setState(
      {
        otp: e.target.value,
        otpIsValid: true,
        otpErrMsg
      },
      () => {
        this.props.resetErrMsg();
      }
    );
  };

  handlePhoneKeyDown = (e) => {
    if (String(e.keyCode) === '13') {
      this.onSendOtpClick();
    }
  };

  handleOtpKeyDown = (e) => {
    if (String(e.keyCode) === '13') {
      this.onVerifyOtpClick();
    }
  };

  onSendOtpClick = () => {
    const { phoneNumber, email } = this.state;
    const setStateObj = { timerIsOn: true };

    if (phoneNumber.length === 0 || phoneNumber.length < 10) {
      setStateObj.phoneNumberIsValid = false;
    } else if (email.length === 0 || email.indexOf('@') === -1) {
      setStateObj.isEmailValid = false;
      setStateObj.emailErrMsg = 'Enter valid email id.';
    } else {
      this.props.onSendOtpClick(phoneNumber);
    }
    this.setState(setStateObj);
  };

  onVerifyOtpClick = (userTypeSelected, userLoginConfig) => {
    const { otp, phoneNumber, email, name } = this.state;
    const setStateObj = {};
    if (otp.length === 0 || otp.length < this.props.otpLength) {
      setStateObj.otpIsValid = false;
    } else if (
      this.props.isNewUserRegistration === true &&
      (userTypeSelected === null || userTypeSelected === undefined)
    ) {
      return;
    } else {
      this.props.onVerifyOtpClick(phoneNumber, otp, name, email, userTypeSelected, userLoginConfig);
    }

    this.setState(setStateObj);
  };

  onEditPhoneNumberClick = () => {
    this.props.resetErrMsg();
    this.props.onEditPhoneNumberClick();
  };

  timeIsOver = () => {
    this.setState({
      timerIsOn: false
    });
  };

  onResendOtpClick = () => {
    this.setState({
      timerIsOn: true
    });
    this.props.resetErrMsg();
    this.props.onSendOtpClick(this.state.phoneNumber);
  };
  render() {
    const {
      onCloseBtnClick,
      otpIsSent,
      sendOtpXhrIsInProgress,
      verifyOtpXhrIsInProgress,
      otpLength,
      isNewUserRegistration
    } = this.props;
    const {
      phoneNumberIsValid,
      phoneNumber,
      phoneNumberErrMsg,
      otpIsValid,
      otp,
      otpErrMsg,
      timerIsOn,
      name,
      email,
      emailErrMsg
    } = this.state;
    return (
      <DialogWrapper>
        {iffun(
          otpIsSent === false,
          () => (
            <SendOtpScreen
              onCloseBtnClick={onCloseBtnClick}
              sendOtpXhrIsInProgress={sendOtpXhrIsInProgress}
              phoneNumberErrMsgProp={this.props.phoneNumberErrMsg}
              phoneNumberIsValid={phoneNumberIsValid}
              onPhoneNumberChange={(e) => this.onPhoneNumberChange(e)}
              onEmailChange={(e) => this.onEmailChange(e)}
              onNameChange={(e) => this.onNameChange(e)}
              name={name}
              email={email}
              emailErrMsg={emailErrMsg}
              phoneNumber={phoneNumber}
              onKeyDown={(e) => this.handlePhoneKeyDown(e)}
              phoneNumberErrMsg={phoneNumberErrMsg}
              onSendOtpClick={this.onSendOtpClick}
            />
          ),
          () => (
            <VerifyOtpScreen
              onCloseBtnClick={onCloseBtnClick}
              verifyOtpXhrIsInProgress={verifyOtpXhrIsInProgress}
              phoneNumber={phoneNumber}
              onEditPhoneNumberClick={this.onEditPhoneNumberClick}
              otpErrMsgProp={this.props.otpErrMsg}
              otpErrMsg={otpErrMsg}
              otpIsValid={otpIsValid}
              otp={otp}
              otpLength={otpLength}
              onOtpChange={(e) => this.onOtpChange(e)}
              onKeyDown={(e) => this.handleOtpKeyDown(e)}
              timeIsOver={this.timeIsOver}
              timerIsOn={timerIsOn}
              onResendOtpClick={this.onResendOtpClick}
              onVerifyOtpClick={this.onVerifyOtpClick}
              isNewUserRegistration={isNewUserRegistration}
            />
          )
        )}
      </DialogWrapper>
    );
  }
}

Login.propTypes = {
  onCloseBtnClick: PropTypes.func,
  sendOtpXhrIsInProgress: PropTypes.bool.isRequired,
  onSendOtpClick: PropTypes.func.isRequired,
  otpIsSent: PropTypes.bool.isRequired,
  verifyOtpXhrIsInProgress: PropTypes.bool.isRequired,
  onVerifyOtpClick: PropTypes.func.isRequired,
  onEditPhoneNumberClick: PropTypes.func.isRequired,
  otpLength: PropTypes.number,
  otpErrMsg: PropTypes.string,
  phoneNumberErrMsg: PropTypes.string,
  resetErrMsg: PropTypes.func.isRequired,
  isNewUserRegistration: PropTypes.bool
};

Login.defaultProps = {
  otpIsSent: false,
  otpLength: 4,
  otpErrMsg: '',
  phoneNumberErrMsg: ''
};
