import styled from 'styled-components';
import { device } from '../../constants/device';

const SecHeading = styled.h3`
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cite {
    font-size: 12px;
    font-weight: 400;
    color: #969696;
  }
  @media ${device.tablet} {
    font-size: 18px;
  }
  .social {
    margin: 5px;
    color: #969696;
    font-size: 16px;
    @media ${device.mobileL} {
      font-size: 12px;
    }
  }
  .refreshed {
    font-size: 12px;
    font-weight: 400;
    color: #969696;
  }
`;

export default SecHeading;
