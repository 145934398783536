import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import LoginWrapper from '../components/Login/LoginWrapper';
import { userServices } from '../services';
import Spinner from '@bit/rivigo.ui.spinner';
import Select from 'react-select';
import { device } from '../constants/device';

const LanesTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px 0;
  align-items: center;

  h2 {
    font-size: 14px;
  }
`;

const Table = styled.table`
  position: relative;
  width: 100%;
  border: 2px solid #e6e6e6;
  border-radius: 8px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  border-collapse: collapse;
  font-size: 14px;
  text-align: left;
  color: #858383;
  thead {
    background: #e6e6e6;

    th {
      padding: 10px;
    }
  }
  tbody {
    tr {
      td {
        padding: 10px;
      }
    }
    tr:not(:first-child).blur {
      td:not(:first-child) {
        filter: blur(3px);
        font-weight: 900;
      }
    }
  }
  @media ${device.mobileL} {
    font-size: 12px;
    tbody {
      tr {
        td {
          padding: 8px;
        }
      }
    }
  }
`;

const SelectDropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    font-size: 12px;
    color: gray;
    margin-bottom: 5px;
    margin-left: 5px;
  }
`;

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '200px'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#e6e6e6' : 'white',
    color: '#000',
    fontWeight: state.isSelected ? 'bold' : 'normal',
    '&:hover': {
      backgroundColor: '#e6e6e6'
    }
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #e33232',
    boxShadow: 'none',
    borderRadius: '55px',
    '&:hover': {
      borderColor: '#e33232'
    },
    '&:focus': {
      borderColor: 'none'
    }
  }),
  singleValue: (base) => ({
    ...base,
    color: '#e33232'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#e33232',
    '&:hover': {
      color: '#e33232'
    }
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  })
};

const HideOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: calc(100% - 75px);
  background: #e6e6e6;
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 8px;
  h2 {
    font-size: 16px;
    color: #000;
    span {
      cursor: pointer;
      color: red;
    }
  }
  @media ${device.mobileL} {
    width: 78%;
    h2 {
      font-size: 12px;
      text-align: center;
    }
  }
`;

const LoginToView = ({ handleLogin }) => {
  return (
    <HideOverlay>
      <h2>
        Please <span onClick={handleLogin}>login here</span> to view the rates for all major lanes from chosen origin.
      </h2>
    </HideOverlay>
  );
};

const MajorLanesTable = (props) => {
  {
    const [selectedCity, setSelectedCity] = useState(null);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const handleShowLoginModal = async () => {
      if (props.isUserLoggedIn === true) {
        await userServices.revokeAuthToken();
        window.localStorage.removeItem('sessionToken');
        window.localStorage.removeItem('userMobile');
        window.location = '/';
      } else {
        setShowLoginModal(true);
      }
    };
    const handleCloseLoginModal = () => {
      setShowLoginModal(false);
    };

    const onOtpVerificationSuccess = (res) => {
      //TODO set actual token
      window.localStorage.setItem('sessionToken', res.response.access_token);
      window.location = '/';
    };

    const handleSelectCity = (option) => {
      setIsLoading(true);
      setSelectedCity(option);
      setData(props.tableData.filter((value) => value.originHubId == option.id));
      setIsLoading(false);
    };

    useEffect(() => {
      // set the selected city to the first city in the list
      if (props.cities && props.cities.length > 0) {
        setSelectedCity(props.cities[0]);
        setData(props.tableData.filter((value) => value.originHubId == props.cities[0].id));
        setIsLoading(false);
      }
    }, [props.cities]);

    return (
      <LanesTableContainer>
        <Heading>
          <h2>Major Lanes</h2>
          <SelectDropdown>
            <label htmlFor='originSelect'>Select origin</label>
            <Select
              styles={customStyles}
              width={'300px'}
              isSearchable={false}
              defaultValue={props.cities[0]}
              onChange={handleSelectCity}
              options={props.cities}
              getOptionLabel={(option) => option.city}
              getOptionValue={(option) => option.id}
            />
          </SelectDropdown>
        </Heading>
        <Table>
          <thead>
            <tr>
              <th>Destination</th>
              <th>Today's Rate(₹)</th>
              <th>Last Week's Rate(₹)</th>
              {/* <th>Change</th> */}
            </tr>
          </thead>
          {isLoading === false ? (
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index} className={props.isUserLoggedIn === false ? 'blur' : ''}>
                    <td>{item.destinationCity ? item.destinationCity : '-'}</td>
                    <td>{item.currentPrice ? item.currentPrice : '-'}</td>
                    <td>{item.lastWeekPrice ? item.lastWeekPrice : '-'}</td>
                    {/* <td>
                      {item.percentageChange ? (
                        <div style={{ color: `${item.percentageChange > 0 ? '#00B027' : '#E32728'}`, display: 'flex' }}>
                          <FontAwesomeIcon icon={item.percentageChange > 0 ? faCaretUp : faCaretDown} size='lg' />
                          {Math.round(Math.abs(item.percentageChange) * 100) / 100}%
                        </div>
                      ) : (
                        '-'
                      )}
                    </td> */}
                  </tr>
                );
              })}
              {props.isUserLoggedIn === false && <LoginToView handleLogin={handleShowLoginModal} />}
            </tbody>
          ) : (
            <div>
              <Spinner />
            </div>
          )}
        </Table>

        {showLoginModal && (
          <LoginWrapper onCloseBtnClick={handleCloseLoginModal} onOtpVerificationSuccess={onOtpVerificationSuccess} />
        )}
      </LanesTableContainer>
    );
  }
};

export default MajorLanesTable;
