import React from 'react';
import styled from 'styled-components';
import FreightIndexGraph from './FreightIndexGraph';
import SearchRates from './SearchRates';
// import NewsFeed from './NewsFeed';
import MajorLanesTable from './MajorLanesTable';
import { device } from '../constants/device';

const Container = styled.div`
  width: 100%;
  padding: 30px 0 60px;
  background: #f6f6fa;
  background: -moz-linear-gradient(-45deg, #f6f6fa 0%, #ffffff 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #f6f6fa), color-stop(100%, #ffffff));
  background: -webkit-linear-gradient(-45deg, #f6f6fa 0%, #ffffff 100%);
  background: -o-linear-gradient(-45deg, #f6f6fa 0%, #ffffff 100%);
  background: -ms-linear-gradient(-45deg, #f6f6fa 0%, #ffffff 100%);
  background: linear-gradient(135deg, #f6f6fa 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6fa', endColorstr='#ffffff', GradientType=1 );
`;

const ContentWrapper = styled.main`
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: flex-start;
  @media ${device.tablet} {
    flex-direction: column-reverse;
  }
`;

const LeftCol = styled.div`
  padding: 0px 30px;
  flex-grow: 1;
  @media ${device.tablet} {
    padding: 30px 0 0;
    width: 100%;
  }
`;

const RightCol = styled.aside`
  flex-grow: 1;
  min-width: 330px;
  max-width: 330px;
  padding: 0px 30px;
  .box {
    background: #ffffff;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  }
  @media ${device.tablet} {
    min-width: 100%;
    max-width: 750px;
  }
`;

const Breadcrumb = styled.div`
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 45px;
  color: #969696;
  font-size: 16px;
  span {
    padding-right: 12px;
    margin-right: 12px;
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;
    &:last-of-type {
      &:after {
        display: none;
      }
    }
    &:after {
      position: absolute;
      content: '';
      top: 7px;
      right: -3px;
      width: 6px;
      height: 6px;
      background: #969696;
      border-radius: 50%;
    }
  }
  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

const MainPage = (props) => {
  return (
    <Container>
      <Breadcrumb>
        <span>Daily Freight</span>
        <span>Spot Rates</span>
        <span>32ft MXL</span>
      </Breadcrumb>
      <ContentWrapper>
        <LeftCol>
          <MajorLanesTable
            isUserLoggedIn={props.isUserLoggedIn}
            tableData={props.nfiHubPriceList}
            cities={props.nfiHubs}
          />
          <FreightIndexGraph />
        </LeftCol>
        <RightCol>
          <SearchRates isUserLoggedIn={props.isUserLoggedIn} />
          {/* <NewsFeed /> */}
        </RightCol>
      </ContentWrapper>
    </Container>
  );
};

export default MainPage;
